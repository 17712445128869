<template>
  <div>
    <div>
      <div class="page-left-column">
        <div class="page-left-content">
          <img alt="Profile picture" src="../assets/profile-pic.png" class="profile-picture">
          <div class="left-section name-info">
            <h2> Bercu Andrei </h2>
            <span> Full Stack Developer </span>
          </div>
          <div class="left-section contact-info">
            <div class="contact-section">
              <img src="../assets/email-icon.svg" />
              <span> andreibercu@yahoo.com </span>
            </div>
            <div class="contact-section">
              <img src="../assets/phone-icon.png" />
              <span> (+40) 0726050004 </span>
            </div>

            <div class="left-section">
              <!-- <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script> -->

            </div>
          </div>

          <button @click="downloadResume" type="button" class="btn btn-light btn-sm resume-download"> Download Resume </button>
        </div>
      </div>

      <div class="page-right-column">
        <div class="page-right-content">

          <div class="top-section">
            <div class="global-title"> About me </div>
          </div>

          <div class="section">
            <div class="section-title"> Work Experience </div>

            <div class="section-item">
              <div class="section-item-time"> 2020 - present </div>
              <div class="section-item-content">

                <div class="job-title d-flex flex-column flex-md-row">
                  <div class="job-name"> Java Software Developer </div>
                  <div class="company-name"> Ezugi, Bucharest </div>
                </div>

                <div class="section-item-subtitle"> Main activities: </div>
                <ul class="job-tasks">
                  <li> Working on the backend of the online gaming platform </li>
                  <li> Developing new games and implementing new features for existing ones </li>
                  <li> Developing new integrations with licensed operators </li>
                  <li> Re-designing the main monolithic server application into microservices using Redis and Kafka </li>
                </ul>

                <div class="section-item-subtitle"> Technologies used: </div>
                <ul class="list-inline">
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Java 8</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Spring Boot</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Spring WebFlux</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Redis</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Kafka</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">MySQL</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Jenkins</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Docker</span></li>
                  <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Git</span></li>
                </ul>
              </div>
            </div>

            <div class="section-item">
               <div class="section-item-time"> 2018 - 2020 </div>
               <div class="section-item-content">

                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> Full Stack Developer </div>
                   <div class="company-name"> Tech Gap Solutions llc, Bucharest </div>
                 </div>

                 <div class="section-item-subtitle"> Main activities: </div>
                 <ul class="job-tasks">
                   <li> Monitoring, maintaining, bug fixing and implementing new features for product traceability software modules used in production plants by companies in the Wine & Liquor industry. </li>
                   <li> Rewriting legacy JSF web applications in the form of SPA applications implemented with Vanilla JS and REST API modules implemented with Spring / Spring Boot / Spring Data JPA. </li>
                   <li> Refactoring database design, integrating java applications with third-party systems using SOAP, modernizing CSS and other full stack activities for various software products owned by the company. </li>
                 </ul>

                 <div class="section-item-subtitle"> Technologies used: </div>
                 <ul class="list-inline">
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Java 8</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Spring Boot</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Spring Data JPA</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">PostgresSQL</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Vanilla JS</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">CSS</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Docker</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Git</span></li>
                 </ul>
               </div>
            </div>

            <div class="section-item">
               <div class="section-item-time"> 2015 - 2018 </div>

               <div class="section-item-content">
                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> APM Developer </div>
                   <div class="company-name"> American Greetings Interactive, Bucharest </div>
                 </div>

                 <div class="section-item-subtitle"> Main activities: </div>
                 <ul class="job-tasks">
                   <li> Creating Python / Bash console applications for load testing web applications in production environments and generating complex performance reports by parsing various application logs. </li>
                   <li> Replacing the licensed (paid) legacy load testing tool used by the company with Apache JMeter, a free open source solution. </li>
                   <li> Developing a Python console application for running performance tests against web applications in the CI environment on every commit and generating performance reports. </li>
                   <li> Implementing new features for internal Python / Django web applications. </li>
                 </ul>

                 <div class="section-item-subtitle"> Technologies used: </div>
                 <ul class="list-inline">
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Python</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Bash</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Django</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Jenkins</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">JMeter</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">AWS</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Graphite</span></li>
                   <li class="list-inline-item"><span class="badge rounded-pill bg-secondary">Apache Subversion</span></li>
                 </ul>
               </div>
            </div>
          </div>

          <div class="section">
            <div class="section-title"> Certificates </div>

            <div class="section-item">
               <div class="section-item-time"> September 2018 </div>

               <div class="section-item-content">
                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> Oracle Certified Professional, Java SE 8 Programmer </div>
                   <div class="company-name"> Issued by Oracle </div>
                 </div>

                 <a class="section-item-subtitle certification-link" target="_blank" href="https://www.youracclaim.com/badges/54418842-85fd-4b55-8ce3-332691b853c7/public_url">
                   OCP badge link
                 </a>
               </div>
            </div>

            <div class="section-item">
               <div class="section-item-time"> August 2018 </div>

               <div class="section-item-content">
                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> Oracle Certified Associate, Java SE 8 Programmer </div>
                   <div class="company-name"> Issued by Oracle </div>
                 </div>

                 <a class="section-item-subtitle certification-link" target="_blank" href="https://www.youracclaim.com/badges/481c6826-1a50-4ac4-9a5b-e15117a08aef/public_url">
                   OCA badge link
                 </a>
               </div>
            </div>
          </div>

          <div class="section">
            <div class="section-title"> Education </div>

            <div class="section-item">
               <div class="section-item-time"> 2016 - 2018 </div>

               <div class="section-item-content">
                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> Master of Business Systems Engineering and Management </div>
                   <div class="company-name"> Politehnica University, Bucharest </div>
                 </div>

                 <div class="section-item-subtitle"> Faculty of Automatic Control and Computer Science </div>
               </div>
            </div>

            <div class="section-item">
               <div class="section-item-time"> 2012 - 2016 </div>

               <div class="section-item-content">
                 <div class="job-title d-flex flex-column flex-md-row">
                   <div class="job-name"> Bachelor of Automatics and Applied Informatics </div>
                   <div class="company-name"> Politehnica University, Bucharest </div>
                 </div>

                 <div class="section-item-subtitle"> Faculty of Automatic Control and Computer Science </div>
               </div>
            </div>
          </div>

          <div class="section">
            <div class="section-title">
              <span> Skills Timeline </span>
            </div>

            <div class="skills-picture-container"  @click="fixedSkills = true" @mouseover="hoverSkills = true" @mouseleave="hoverSkills = false">
              <img alt="skills infographic" src="../assets/skills-pic.png" class="skills-picture" :class="{ 'skills-picture-hover': hoverSkills }">
              <img src="../assets/maximize-icon.png" class="skills-maximize-icon">
            </div>
          </div>

          <transition name="skills">
          <div id="fixed-skills-container" v-if="fixedSkills"  @click="fixedSkills = false">
            <div class="fixed-skills-title"> Skills Timeline </div>

            <div class="fixed-skills-picture-container"  @click="fixedSkills = true" @mouseover="hoverFixedSkills = true" @mouseleave="hoverFixedSkills = false">
              <img alt="skills infographic" src="../assets/skills-pic.png" class="fixed-skills-picture" :class="{ 'fixed-skills-picture-hover': hoverFixedSkills }">
              <img src="../assets/minimize-icon.png" class="skills-minimize-icon">
            </div>
          </div>
          </transition>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    // msg: String
  },
  data() {
    return {
      fixedSkills: false,
      hoverSkills: false,
      hoverFixedSkills: false,
    }
  },
  methods: {
    forceFileDownload(response, filename){
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    },

    downloadResume() {
      this.$http({
        method: 'get',
        url: 'BercuAndrei-CV.pdf', // file is in public folder
        responseType: 'blob'
      })
      .then(response => {
        this.forceFileDownload(response, 'BercuAndrei-CV.pdf')
      })
      .catch(() => console.log('error occured'))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
